import addressCardSolid from "../images/address-card-solid.svg";
import mapMarledSolid from "../images/map-marked-solid.svg";
import type { HealthEntityWith } from "../interfaces/interfaces";

export function AdressHealthInsuranceList({
  entity,
}: {
  entity: HealthEntityWith;
}): JSX.Element {
  const locationsByState: Record<string, string[]> =
    entity.healthEntityLocation.reduce(
      (
        acc: Record<string, string[]>,
        location: { city?: { UF?: string; name?: string } },
      ) => {
        const uf = location.city?.UF ?? "";
        if (!acc[uf]) {
          acc[uf] = [];
        }
        if (location.city?.name && !acc[uf].includes(location.city.name)) {
          acc[uf].push(location.city.name);
        }
        return acc;
      },
      {},
    );
  const healthInsurancesList = entity.healthEntityHealthInsurance.map(
    (i) => i.healthInsurance?.name,
  );
  const healthInsurances = Array.from(new Set(healthInsurancesList)).join(", ");

  return (
    <section aria-label="resumo endereço e planos de saúde">
      {Object.keys(locationsByState).map((state: string) => (
        <AdressHealthInsuraceListItem
          icon={mapMarledSolid}
          key={state}
          text={`${locationsByState[state].join(", ")} - ${state.toUpperCase()}`}
        />
      ))}
      {healthInsurances ? (
        <AdressHealthInsuraceListItem
          icon={addressCardSolid}
          key="healthInsurances"
          text={healthInsurances}
        />
      ) : null}
    </section>
  );
}

function AdressHealthInsuraceListItem({
  icon,
  text,
}: {
  icon: string;
  text: string;
}): JSX.Element {
  return (
    <div className="flex gap-3">
      <img
        alt=""
        className="relative h-5 w-5 shrink-0 overflow-hidden text-secondary-500"
        loading="eager"
        src={icon}
      />
      <div className="text-xs font-medium">{text}</div>
    </div>
  );
}
