import { Button } from "@/components/ui/button";
import { Adsense } from "@ctrl/react-adsense";
import { Link } from "@remix-run/react";
import type { HealthEntityWith } from "../interfaces/interfaces";
import MemberSnippet from "./member-snippet";

export function SearchCardItem({
  entity,
  index,
}: {
  entity: HealthEntityWith;
  index: number;
}): JSX.Element {
  return (
    <>
      <li className="border-solid border-neutral-200 [&:not(:last-child)]:border-b-[1px]">
        <section
          aria-label="perfil do membro"
          className="flex flex-col py-0 pb-4 gap-6 md:flex-row md:pb-10 pt-6 md:pt-10"
        >
          <MemberSnippet entity={entity} />
          <Link to={`/${entity.currentUrl?.toLowerCase()}`}>
            <Button
              className="md:w-40"
              type="button"
              size="fill"
              variant="secondary"
            >
              Entre em contato
            </Button>
          </Link>
        </section>
      </li>
      {(index + 1) % 2 === 0 && (
        <Adsense
          client="ca-pub-9847031185780341"
          slot="2702983832"
          style={{ display: "block" }}
          responsive="true"
          format="auto"
        />
      )}
    </>
  );
}
