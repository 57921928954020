import { Link } from "@remix-run/react";
import { ChevronRight, Home } from "lucide-react";
import type { ReactNode } from "react";
import { Wrapper } from "./wrapper";

/**
 * Renders a breadcrumb navigation component.
 *
 * @param children - The breadcrumb link elements to render.
 * @returns The breadcrumb navigation JSX element.
 */
export function Breadcrumb({ children }: { children: ReactNode }): JSX.Element {
  return (
    <div className="bg-neutral-50">
      <Wrapper className="py-2">
        <nav className="h-12 md:h-16 text-xs  flex flex-row items-center justify-start gap-[8px]">
          {children}
        </nav>
      </Wrapper>
    </div>
  );
}

/**
 * Renders a breadcrumb item with an optional icon, label and required url.
 *
 * @param icon - Optional icon to render before the label.
 * @param label - Optional label text to render in the link.
 * @param url - Required url for the breadcrumb link.
 */
export function BreadcrumbItem({
  icon,
  label,
  url,
}: {
  icon?: string;
  label?: string;
  url: string;
}): JSX.Element {
  return (
    <>
      {icon ? (
        <Link to={url}>
          <Home className="text-primary-600" size={16} />
        </Link>
      ) : (
        <>
          <ChevronRight className="text-neutral-400" size={16} />
          <Link to={url}>
            <div className="relative text-primary-600 font-medium leading-[16px]">
              {label}
            </div>
          </Link>
        </>
      )}
    </>
  );
}
