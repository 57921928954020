import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Adsense } from "@ctrl/react-adsense";
import { useLocation } from "@remix-run/react";
import { remove as removeDiacritics } from "diacritics";
import type { States } from "~/data/data";
import searchPlusSolid from "../images/search-plus-solid.svg";
import type { HealthEntityWith } from "../interfaces/interfaces";
import { Breadcrumb, BreadcrumbItem } from "./breadcrumb";
import SearchCardList from "./search-card-list";
import { type ListItem, SearchForm } from "./search-form";
import { SearchPagination } from "./search-pagination";
import { SeoList } from "./seo-list";
import { Wrapper } from "./wrapper";

function generateSearchBreadcumbs(
  pathname: string,
  query: Record<string, string> | null,
): JSX.Element[] {
  const pathParts = pathname.split("/").filter((p) => p);

  const city = query?.city ? removeDiacritics(query.city).toUpperCase() : "";
  const uf = query?.uf ? query.uf.toUpperCase() : "";

  const breadcrumbItems = pathParts.map((part: string, index: number) => {
    const key = part.replace("-", " ").toUpperCase();

    if (uf && key === uf) {
      return <BreadcrumbItem key={key} label={uf} url={`/${uf}`} />;
    }
    if (city && key === city) {
      return (
        <BreadcrumbItem
          key={key}
          label={query ? query.city : ""}
          url={`/${uf}/${city}`}
        />
      );
    }
    const label = part
      .split("-")
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ");

    const url = `/${pathParts.slice(0, index + 1).join("/")}`;
    return <BreadcrumbItem key={url} label={label} url={url} />;
  });
  return breadcrumbItems;
}

export function Search({
  items,
  randomSEO,
  healthInsurances,
  specialties,
  query = null,
  stateAndCities,
}: {
  items: HealthEntityWith[];
  randomSEO: Record<string, string>;
  healthInsurances: ListItem[];
  specialties: ListItem[];
  query: Record<string, string> | null;
  stateAndCities: States[];
}): JSX.Element {
  const location = useLocation();

  let content: JSX.Element;
  if (items.length > 0) {
    content = (
      <>
        <SearchCardList items={items} />
        <SearchPagination />
      </>
    );
  } else
    content = (
      <>
        <div className="py-4">
          <Adsense
            client="ca-pub-9847031185780341"
            slot="7930537009"
            style={{ display: "block" }}
            responsive="true"
            format="auto"
          />
        </div>
        <div className="pt-6">
          <NoSearchResults />
        </div>
      </>
    );

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem icon="/homesolid.svg" url="/" />
        {generateSearchBreadcumbs(location.pathname, query)}
      </Breadcrumb>
      <SearchForm
        color="secondary"
        showFilter
        healthInsurances={healthInsurances}
        specialties={specialties}
        query={query}
        stateAndCities={stateAndCities}
      />
      <Wrapper>
        {content}
        <div className="pb-5">
          <div>
            <Accordion type="single" collapsible>
              <AccordionItem className="border-b-0" value="item1">
                <AccordionTrigger className="text-neutral-700 text-sm font-semibold">
                  Quais fatores são considerados ao classificar os profissionais
                  na lista?
                </AccordionTrigger>
                <AccordionContent>
                  Os profissionais na lista são classificados com base em um
                  conjunto dinâmico de critérios. Estes incluem a qualidade das
                  avaliações que recebem e a sua capacidade de resposta aos
                  pacientes, entre outros fatores. Isso garante que os rankings
                  reflitam tanto o envolvimento dos profissionais quanto o
                  desempenho geral deles.
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div className="py-10">
          <SeoList
            icon={searchPlusSolid}
            items={randomSEO}
            title="Outras buscas relevantes"
            horizontal
          />
        </div>
      </Wrapper>
    </>
  );
}

function NoSearchResults(): JSX.Element {
  return (
    <div className="flex py-10 px-6 justify-center flex-col items-center gap-6 self-stretch border border-dashed rounded-3xl border-neutral-200 bg-neutral-50">
      <div className="flex p-4 items-center gap-2 rounded-[40px] bg-neutral-100">
        <img alt="search icon" src={searchPlusSolid} width={24} height={24} />
      </div>
      <div>
        <p className="self-stretch text-center text-base font-semibold text-neutral-500">
          Sua busca não retornou nenhum resultado
        </p>
        <p className="pt-5 font-normal text-sm text-center">
          Não encontramos resultados correspondentes à sua pesquisa.
        </p>
        <p className="pt-5 font-normal text-sm text-center">
          Verifique os parâmetros, refine sua busca e tente novamente.
        </p>
      </div>
    </div>
  );
}
