import {
  type HeadersFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
  json,
} from "@remix-run/cloudflare";
import { useLoaderData } from "@remix-run/react";
import { getSearchDropdownContent } from "~/lib/utils";
import { Profile } from "../components/profile";
import { Search } from "../components/search";
import { generateProfileSEO, generateSearchSEO } from "../lib/meta-description";
import {
  capitalize,
  extractSearchQueryParts,
  generateRandomRecord,
} from "../lib/search-utils";
import { fetchHealthEntity, search } from "../services/helpsaude/db.server";

export const meta: MetaFunction<typeof loader> = ({ data, location }) => {
  if (data !== undefined && Array.isArray(data.data)) {
    return generateSearchSEO(location.pathname);
  }
  if (data !== undefined && !Array.isArray(data.data)) {
    return generateProfileSEO(data.data);
  }
  return [];
};

export const headers: HeadersFunction = () => ({
  "Cache-Control": "max-age=300, s-maxage=3600",
});

export async function loader({ context, params, request }: LoaderFunctionArgs) {
  const filePath = params["*"];
  const parts = filePath?.split("/").filter((x) => x);

  const url = new URL(request.url);
  const page = url.searchParams.get("page");

  if (parts?.length === 1) {
    const isHealthEntity = await fetchHealthEntity(
      context.cloudflare.env.hs,
      parts[0] ?? "",
    );
    // console.log("isHealthEntity", isHealthEntity);

    if (isHealthEntity !== undefined) {
      return json({
        data: isHealthEntity,
        randomUrls: generateRandomRecord(12, [
          "specialties",
          "cities",
          "healthInsurance",
        ]),
      });
    }
  }

  const query = extractSearchQueryParts(parts);
  console.log("query", query);

  // check for 404 - not found in case of profile page with invalid seach arguments
  if (query.hasError) {
    throw new Response(null, {
      status: 404,
      statusText: "Not Found",
    });
  }

  // execute search query
  if (parts && parts.length >= 1) {
    const formatedResults = await search(
      context.cloudflare.env.hs,
      query,
      Number(page) || 1,
    );

    const { healthInsurancesList, specialtiesList, statesAndCities } =
      await getSearchDropdownContent();

    return json({
      total: formatedResults?.total,
      data: formatedResults?.formatedResults,
      randomUrls: generateRandomRecord(6, ["specialties"]),
      healthInsurancesList,
      specialtiesList,
      statesAndCities,
      query: {
        uf: query.state?.toUpperCase(),
        city: query.cityFullName,
        name: capitalize(query.name),
        sl: capitalize(query.specialty),
        hi: capitalize(query.healthInsurance),
        nb: capitalize(query.neighborhood),
        sltype: query.specialtyType,
        error: query.hasError,
      },
    });
  }
}

export default function SearchOrProfile(): JSX.Element {
  const data = useLoaderData<typeof loader>();
  if (Array.isArray(data.data)) {
    return (
      <Search
        items={data.data}
        randomSEO={data.randomUrls}
        healthInsurances={data.healthInsurancesList}
        specialties={data.specialtiesList}
        stateAndCities={data.statesAndCities}
        query={data.query}
      />
    );
  }
  return <Profile item={data.data} randomSEO={data.randomUrls} />;
}
