import { Link } from "@remix-run/react";
import { BadgeCheck } from "lucide-react";
import userCicleAvatar from "../images/user-circle-avatar.svg";
import type { HealthEntityWith } from "../interfaces/interfaces";
import { AdressHealthInsuranceList } from "./adress-health-insurance-list";
import SpecialtiesListShort from "./specialties-list-short";

function MemberSnippet({
  entity,
  showClaim = false,
}: {
  entity: HealthEntityWith;
  showClaim?: boolean;
}): JSX.Element {
  return (
    <>
      {/* Mobile layout */}
      <div className="md:hidden">
        <div className="flex items-start gap-2">
          <Avatar url={entity.mainImagePath} width={48} height={48} />
          <div>
            <NameBadgeOrVerify showClaim={showClaim} entity={entity} />
          </div>
        </div>
        <div className="mt-4 flex flex-col gap-[6px]">
          <div className="flex flex-col gap-2">
            <HealthAssociationBadge entity={entity} />
            <SpecialtiesListShort
              specialties={entity.healthEntitySpecialty.map(
                (healthEntitySpecialty) =>
                  healthEntitySpecialty.specialty?.name ?? "",
              )}
            />
          </div>
          <AdressHealthInsuranceList entity={entity} />
        </div>
      </div>

      {/* Desktop layout */}
      <div className="hidden md:grid md:grid-cols-[120px_1fr_auto] md:gap-4 md:items-start md:w-full">
        <Avatar url={entity.mainImagePath} width={120} height={120} />
        <div>
          <NameBadgeOrVerify showClaim={showClaim} entity={entity} />
          <div className="mt-2 space-y-2">
            <div className="flex flex-col gap-2">
              <HealthAssociationBadge entity={entity} />
              <SpecialtiesListShort
                specialties={entity.healthEntitySpecialty.map(
                  (healthEntitySpecialty) =>
                    healthEntitySpecialty.specialty?.name ?? "",
                )}
              />
            </div>
            <AdressHealthInsuranceList entity={entity} />
          </div>
        </div>
      </div>
    </>
  );
}

function HealthAssociationBadge({
  entity,
}: {
  entity: HealthEntityWith;
}): JSX.Element | null {
  if (entity.healthEntityHealthAssociationAffiliateNumber.length > 0) {
    return (
      <div className="w-fit rounded-3xl bg-primary-50 py-0.5 px-2 text-center text-primary-600">
        <div className="text-xs font-medium">
          {
            entity.healthEntityHealthAssociationAffiliateNumber[0]
              .healthAssociation?.acronym
          }
          -
          {
            entity.healthEntityHealthAssociationAffiliateNumber[0]
              .affiliateNumber
          }
        </div>
      </div>
    );
  }
  return null;
}

function Avatar({
  url,
  width,
  height,
}: {
  url: string | null;
  width: number;
  height: number;
}): React.ReactNode {
  let urlFinal = userCicleAvatar;
  if (url !== null && url.trim() !== "") {
    const CDN =
      "https://www.helpsaude.com/cdn-cgi/imagedelivery/96D5KstIJsryMlVJjlvB4g";
    const image = `/imagens/${url.split("/imagens/")[1]}`;
    urlFinal = `${CDN}${image}/w=${width},h=${height}`;
  }
  return (
    <img
      className="rounded-lg"
      alt="avatar"
      width={width}
      height={height}
      src={urlFinal}
    />
  );
}

function NameBadgeOrVerify({
  entity,
  showClaim = false,
}: {
  entity: HealthEntityWith;
  showClaim?: boolean;
}): JSX.Element {
  return (
    <>
      <h3 className="text-base font-semibold">{entity.fullName}</h3>
      {!entity.wasValidated ? (
        <p className="text-xs leading-[16px] text-neutral-700">
          {showClaim ? (
            <>
              <span>Informações desatualizadas? </span>
              <Link
                to="https://form.jotform.com/200194906035147"
                className="font-semibold text-primary-6-default"
              >
                Clique aqui.
              </Link>
            </>
          ) : null}
        </p>
      ) : (
        <div className="flex flex-row items-center justify-start">
          <BadgeCheck className="z-10 text-green-7" />
          <div className="ml-[-8px] flex flex-row items-center justify-center rounded-bl-none rounded-br-3xl rounded-tl-none rounded-tr-3xl bg-green-0 py-0.5 pl-3 pr-2">
            <div className="font-plain-button-medium relative text-center text-xs font-medium leading-[16px] text-green-7">
              Verificado
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MemberSnippet;
