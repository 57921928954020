import { Link, useSearchParams } from "@remix-run/react";
import { ChevronLeft, ChevronRight } from "lucide-react";

export function SearchPagination(): JSX.Element {
  const [searchParams] = useSearchParams();
  const $page = Number(searchParams.get("page")) || 1;
  const totalPages = 100;
  const canPageBackwards = $page > 1;
  const canPageForwards = $page < totalPages;
  return (
    <section className="flex h-20 flex-row items-center">
      <div className="flex flex-1 flex-row items-start gap-3 justify-center w-max">
        {canPageBackwards ? (
          <Link prefetch="viewport" to={`?page=${$page - 1}`}>
            <button
              className="flex cursor-pointer flex-row items-center justify-center self-stretch rounded-lg bg-primary-100 px-5 py-4"
              type="button"
            >
              <ChevronLeft className="text-primary-700" />
            </button>
          </Link>
        ) : null}
        {canPageForwards ? (
          <Link prefetch="viewport" to={`?page=${$page + 1}`}>
            <button
              className="flex cursor-pointer flex-row items-center justify-center rounded-lg bg-primary-100 px-5 py-4"
              type="button"
            >
              <div className="relative text-center font-plain-button-medium text-base font-semibold leading-[24px] text-primary-700">
                Ver Mais Profissionais
              </div>
              <ChevronRight className="text-primary-700" />
            </button>
          </Link>
        ) : null}
      </div>
    </section>
  );
}
