import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Adsense } from "@ctrl/react-adsense";
import searchPlusSolid from "../images/search-plus-solid.svg";
import type { HealthEntityWith } from "../interfaces/interfaces";
import { specialtyToDescription, toUrlFilename } from "../lib/search-utils";
import { AdressSnippetList } from "./adress-snippet";
import { Breadcrumb, BreadcrumbItem } from "./breadcrumb";
import MemberSnippet from "./member-snippet";
import { SeoList } from "./seo-list";
import { Wrapper } from "./wrapper";

//FIXME
function generateProfileBreadcrumbs(item: HealthEntityWith): JSX.Element[] {
  const crumbs = [];
  console.log(item);

  // Home icon
  crumbs.push(<BreadcrumbItem key="home" icon="/home.svg" url="/" />);

  // First specialty
  if (item.healthEntitySpecialty.length > 0) {
    const firstSpecialty = item.healthEntitySpecialty[0].specialty?.name ?? "";

    crumbs.push(
      <BreadcrumbItem
        key={firstSpecialty}
        label={firstSpecialty}
        url={toUrlFilename(firstSpecialty)}
      />,
    );
  }

  // First address state and city
  if (item.healthEntityLocation.length > 0) {
    const address = item.healthEntityLocation[0];
    const city = address.city?.name ?? "";
    const state = address.city?.UF ?? "";

    crumbs.push(
      <BreadcrumbItem
        key={state.toLocaleUpperCase()}
        label={state.toLocaleUpperCase()}
        url={`/${state}`}
      />,
    );
    crumbs.push(
      <BreadcrumbItem
        key={city}
        label={city}
        url={`/${state}/${toUrlFilename(city)}`}
      />,
    );
  }

  // Profile url
  crumbs.push(
    <BreadcrumbItem
      key={item.fullName ?? ""}
      label={item.fullName ?? ""}
      url={`${item.currentUrl}`}
    />,
  );

  return crumbs;
}

export function Profile({
  item,
  randomSEO,
}: {
  item: HealthEntityWith;
  randomSEO: Record<string, string>;
}): JSX.Element {
  return (
    <>
      <Breadcrumb>{generateProfileBreadcrumbs(item)}</Breadcrumb>
      <div className="ml-auto mr-auto max-w-4xl pl-5 pr-5">
        <div className="pt-6 pb-4 border-b border-neutral-100">
          <MemberSnippet showClaim entity={item} />
        </div>
      </div>
      <Wrapper className="py-4">
        <Adsense
          client="ca-pub-9847031185780341"
          slot="7801818143"
          style={{ display: "block" }}
          responsive="true"
          format="auto"
        />
      </Wrapper>
      <div className="pt-4">
        <AdressSnippetList item={item} />
      </div>
      <div className="ml-auto mr-auto max-w-4xl pl-5 pr-5">
        <div className="font-semibold text-primary-900, text-base">
          Mais Detalhes
        </div>
        <h1 className="font-medium text-neutral-700 text-base pb-4">
          {item.fullName ?? ""}
        </h1>
        {item.about ? (
          <div className="text-neutral-900 text-sm font-normal pb-8">
            {item.about}
          </div>
        ) : null}
        {item.healthEntitySpecialty.length > 0 && (
          <div className="pb-8 border-b border-neutral-100">
            <div className="flex flex-col md:flex-col border border-solid border-neutral-100 rounded-2xl bg-neutral-50 px-5 py-5">
              <div className="text-neutral-900 text-base font-semibold pb-3">
                Especialidades
              </div>
              <div className="pb-5">
                {item.healthEntitySpecialty.map((specialty, index) => {
                  const accordionContent = specialtyToDescription(
                    specialty.specialty?.name ?? "",
                  );

                  return (
                    <div key={specialty.id}>
                      <Accordion type="single" collapsible>
                        <AccordionItem
                          className="border-b-0"
                          value={`item${index}`}
                        >
                          <AccordionTrigger className="text-neutral-700 text-sm font-semibold">
                            {specialty.specialty?.name ?? ""}
                          </AccordionTrigger>
                          <AccordionContent>
                            {accordionContent}
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        <div className="py-10">
          <SeoList
            icon={searchPlusSolid}
            items={randomSEO}
            title="Últimas Buscas"
            horizontal
          />
        </div>
      </div>
    </>
  );
}
