import type { HealthEntityWith } from "../interfaces/interfaces";
import { SearchCardItem } from "./search-card-item";

export default function SearchCardList({
  items,
}: {
  items: HealthEntityWith[];
}): JSX.Element {
  return (
    <ul>
      {items.map((item, index) => (
        <SearchCardItem entity={item} index={index} key={item.id} />
      ))}
    </ul>
  );
}
