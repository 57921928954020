import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Phone } from "lucide-react";
import type { HealthEntityWith } from "../interfaces/interfaces";

export function AdressSnippetList({
  item,
}: {
  item: HealthEntityWith;
}): JSX.Element {
  return (
    <>
      {item.healthEntityLocation.length > 0 && (
        <div className="ml-auto mr-auto max-w-4xl pl-5 pr-5 gap-4 pb-10">
          <ul className="flex flex-col gap-4">
            {item.healthEntityLocation.map((location) => {
              return (
                <AdressSnippet
                  key={location.id}
                  state={location.city?.UF ?? ""}
                  city={location.city?.name ?? ""}
                  road={location.road ?? ""}
                  number={location.number ?? ""}
                  cep={location.CEP ?? ""}
                  neighborhood={location.neighborhood?.name ?? ""}
                  phone={location.phoneNumber.map((phone) => ({
                    areaCode: phone.areaCode ?? 0,
                    number: phone.number ?? 200000000,
                  }))}
                />
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}

export function AdressSnippet({
  state,
  city,
  road,
  number,
  cep,
  neighborhood,
  phone,
  variant = "primary",
}: {
  state: string;
  city: string;
  road: string;
  number: string;
  cep: string;
  neighborhood: string;
  phone?: { areaCode: number; number: number }[];
  variant?: "primary" | "secondary";
}): JSX.Element {
  return (
    <div className="flex flex-col border border-solid border-neutral-100 rounded-2xl bg-neutral-50 px-5 py-5">
      <div className="w-full">
        <div className="flex gap-2 text-primary-900 text-sm font-semibold">
          <div className="uppercase">{state}</div>
          <div>{city}</div>
          <div className="uppercase">{neighborhood}</div>
        </div>
        <div className="text-xs text-neutral-700 font-normal pb-4">
          {road}, {number} - {cep}
        </div>
      </div>
      {variant === "primary" && phone ? (
        <div className="flex gap-2">
          <Dialog>
            <DialogTrigger asChild>
              <Button className="w-full" variant="secondary">
                Marcar Consulta
              </Button>
            </DialogTrigger>
            <DialogContent className="border bg-white border-solid border-neutral-200 rounded-2xl w-9/10 px-0 py-0">
              <DialogHeader>
                {/* <DialogTitle>Nome do médico</DialogTitle> */}
                <DialogDescription>
                  <AdressSnippet
                    state={state}
                    city={city}
                    road={road}
                    number={number}
                    cep={cep}
                    neighborhood={neighborhood}
                    variant="secondary"
                    phone={phone}
                  />
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>
          <Dialog>
            <DialogTrigger asChild>
              <Button variant="outline">Mapa</Button>
            </DialogTrigger>
            <DialogContent className="border bg-white border-solid border-neutral-200 rounded-2xl w-9/10 px-2 py-2">
              <DialogHeader>
                <DialogDescription>
                  <iframe
                    title="google-maps"
                    width="300"
                    height="200"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyB1XOYSw2_Y88fc-d1J_weOR1KFYK2ecvU&q=${road},${number} - ${cep}`}
                  />
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>
        </div>
      ) : null}
      {variant === "secondary" && (
        <div className="px-4 py-4 bg-secondary-50 rounded-lg flex flex-col gap-3">
          <div className="w-fit items-start text-sm font-semibold text-primary-800">
            Agende sua consulta
          </div>
          {phone?.map((p) => (
            <div
              key={`${p.areaCode}-${p.number}`}
              className="px-4 py-2 gap-2 border-2 rounded-lg border-secondary-500 border flex justify-center"
            >
              <Phone width={16} height={16} className="text-secondary-500" />
              <a
                className="text-secondary-500 text-sm font-semibold"
                href={`tel:(${p.areaCode}) ${p.number}`}
              >
                {`(${p.areaCode}) ${p.number}`}
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
