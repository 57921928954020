interface SpecialtiesListShortProps {
  specialties: string[];
}

export default function SpecialtiesListShort(
  props: SpecialtiesListShortProps,
): JSX.Element {
  return (
    <ul className="flex flex-wrap gap-2">
      {props.specialties.map((specialty, index) => (
        <li
          key={`${specialty}-${
            // biome-ignore lint/suspicious/noArrayIndexKey: Não sei muito o que usar aqui que não o index
            index
          }`}
          className="flex justify-start gap-2 items-center"
        >
          <div className="flex-1 text-xs font-medium">{specialty}</div>
          {index !== props.specialties.length - 1 && (
            <div className="h-1 w-1 rounded-[50%] bg-secondary-500" />
          )}
        </li>
      ))}
    </ul>
  );
}
